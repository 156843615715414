<template>
  <div>
    <l-table
        ref="lTableRef"
        :module-name="KITS_MODULE_NAME"
        :table-columns="trashTableColumns"
        :is-searchable="false"
        :table-config-options="getTableConfigOptions()"
        back-to-list-path="home-main-catalog-list-kits"
    >
      <template #cell(name)="{ data }">
        <div
            v-b-tooltip.noninteractive.hover
            class="name-cell"
            :title="getValue(data, 'name') ? getValue(data, 'name') : '—'"
        >
          {{ getValue(data, 'name') ? getValue(data, 'name') : '—' }}
        </div>
      </template>
      <template #cell(name)="{ data }">
        <b-link
            :to="{ name: 'home-kits-view' , params: { id: data.item.id }}"
            class="text-dark text-wrap d-flex align-items-center"
            style="gap: 8px"
        >
          <feather-icon
              icon="LKitsIcon"
              size="18"
          />
          <span>
            {{ data.item.name }}
          </span>
        </b-link>
      </template>
      <template #cell(is_active)="{ data }">
        {{ data.item.is_active === null ? '---' : data.item.is_active ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(discount)="{ data }">
        {{ data.item.discount }}%
      </template>
      <template #cell(status)="{ data }">
        {{ data.item.status === null ? '—' : data.item.status ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(action)="{ data }">
        <feather-icon
            icon="LTrashIcon"
            size="30"
            style="padding: 2px 6px"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="remove(data.item)"
        />
        <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="30"
            style="padding: 4px"
            class="feather-icon border-dotted cursor-pointer defaultIconColor"
            @click="restoreContact(data.item)"
        />
      </template>
    </l-table>
  </div>
</template>
<script>
import LTable from "@/views/components/LTable/LTable.vue";
import config from "../kitsConfig";
import {BLink, VBTooltip} from "bootstrap-vue";

export default {
  name: "KitsTrashList",
  components: {BLink, LTable},
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    getValue(data, tableColumnName) {
      return data.item[tableColumnName] ?? {}
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.KITS_MODULE_NAME}/del`)
          .then(
              () => {
                this.refetchData()
              },
          )
    },
    restoreContact(data) {
      this.confirmNotification(this, data, `${this.KITS_MODULE_NAME}/setActive`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
          .then(() => {
            this.refetchData()
          })
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.KITS_MODULE_NAME}/getInActiveKitsList`,
      }
    },
  },
  setup() {
    const KITS_MODULE_NAME = 'kits'
    const {trashTableColumns, fields, ACCESS_ABILITY_KITS } = config()
    return {
      KITS_MODULE_NAME,
      trashTableColumns,
      fields,
      ACCESS_ABILITY_KITS,
    }
  },
};
</script>
<style scoped>

</style>