var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.KITS_MODULE_NAME,"table-columns":_vm.trashTableColumns,"is-searchable":false,"table-config-options":_vm.getTableConfigOptions(),"back-to-list-path":"home-main-catalog-list-kits"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var data = ref.data;
return [_c('b-link',{staticClass:"text-dark text-wrap d-flex align-items-center",staticStyle:{"gap":"8px"},attrs:{"to":{ name: 'home-kits-view' , params: { id: data.item.id }}}},[_c('feather-icon',{attrs:{"icon":"LKitsIcon","size":"18"}}),_c('span',[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}},{key:"cell(is_active)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.is_active === null ? '---' : data.item.is_active ? 'Active' : 'Inactive')+" ")]}},{key:"cell(discount)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.discount)+"% ")]}},{key:"cell(status)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.status === null ? '—' : data.item.status ? 'Active' : 'Inactive')+" ")]}},{key:"cell(action)",fn:function(ref){
var data = ref.data;
return [_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted defaultIconColor",staticStyle:{"padding":"2px 6px"},attrs:{"icon":"LTrashIcon","size":"30"},on:{"click":function($event){return _vm.remove(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"feather-icon border-dotted cursor-pointer defaultIconColor",staticStyle:{"padding":"4px"},attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"30"},on:{"click":function($event){return _vm.restoreContact(data.item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }